// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-craft-cms-bournemouth-js": () => import("./../src/pages/services/craft-cms-bournemouth.js" /* webpackChunkName: "component---src-pages-services-craft-cms-bournemouth-js" */),
  "component---src-pages-services-email-templates-js": () => import("./../src/pages/services/email-templates.js" /* webpackChunkName: "component---src-pages-services-email-templates-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-shopify-development-bournemouth-js": () => import("./../src/pages/services/shopify-development-bournemouth.js" /* webpackChunkName: "component---src-pages-services-shopify-development-bournemouth-js" */),
  "component---src-pages-services-shopify-support-js": () => import("./../src/pages/services/shopify-support.js" /* webpackChunkName: "component---src-pages-services-shopify-support-js" */),
  "component---src-pages-services-shopify-training-js": () => import("./../src/pages/services/shopify-training.js" /* webpackChunkName: "component---src-pages-services-shopify-training-js" */),
  "component---src-pages-services-website-design-bournemouth-js": () => import("./../src/pages/services/website-design-bournemouth.js" /* webpackChunkName: "component---src-pages-services-website-design-bournemouth-js" */),
  "component---src-pages-services-website-development-bournemouth-js": () => import("./../src/pages/services/website-development-bournemouth.js" /* webpackChunkName: "component---src-pages-services-website-development-bournemouth-js" */),
  "component---src-pages-services-website-maintenance-js": () => import("./../src/pages/services/website-maintenance.js" /* webpackChunkName: "component---src-pages-services-website-maintenance-js" */),
  "component---src-pages-services-website-optimisation-js": () => import("./../src/pages/services/website-optimisation.js" /* webpackChunkName: "component---src-pages-services-website-optimisation-js" */),
  "component---src-pages-services-website-security-js": () => import("./../src/pages/services/website-security.js" /* webpackChunkName: "component---src-pages-services-website-security-js" */),
  "component---src-pages-services-wordpress-development-bournemouth-js": () => import("./../src/pages/services/wordpress-development-bournemouth.js" /* webpackChunkName: "component---src-pages-services-wordpress-development-bournemouth-js" */),
  "component---src-pages-services-wordpress-hosting-js": () => import("./../src/pages/services/wordpress-hosting.js" /* webpackChunkName: "component---src-pages-services-wordpress-hosting-js" */),
  "component---src-pages-services-wordpress-maintenance-js": () => import("./../src/pages/services/wordpress-maintenance.js" /* webpackChunkName: "component---src-pages-services-wordpress-maintenance-js" */),
  "component---src-pages-services-wordpress-security-js": () => import("./../src/pages/services/wordpress-security.js" /* webpackChunkName: "component---src-pages-services-wordpress-security-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-wordpress-speed-improvements-js": () => import("./../src/pages/wordpress-speed-improvements.js" /* webpackChunkName: "component---src-pages-wordpress-speed-improvements-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

