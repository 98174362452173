module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-22071944-15"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Old Salt","short_name":"Old Salt","start_url":"/","background_color":"#2bf0b9","theme_color":"#2bf0b9","display":"minimal-ui","icon":"src/images/fav.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5b3d81043e65285a3523e072ffb54071"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
